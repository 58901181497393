import React, { useReducer, useEffect, useContext } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  Paper,
  Button,
  IconButton,
  Avatar,
  Box,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';
import SnoozedIcon from '@mui/icons-material/Snooze';
import MissingIcon from '@mui/icons-material/ErrorOutline';

import ApprovedIcon from '@mui/icons-material/CheckCircleOutline';
import ToolIcon from '@mui/icons-material/BuildOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import configData from "../../config.json";
import './activity-list.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { reducer } from './reducer';
import { ToolContext } from './tool-list';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(toolName, toolId, reason, date, time, status) {
  return { toolName, toolId, reason, date, time, status };
}

const rows = [
  createData('Oats Tool/ 600 Amp Tool', 'ABCD1123', 'Missing', '11/22/21', '11:23:45 AM', 'success'),
  createData('Oats Tool/ 200 Amp Tool', 'ABCD1124', 'Tag Damaged', '01/08/22', '09:43:45 AM', 'success'),
  createData('Fluke Meter', 'ABCD1125', 'Missing', '10/26/21', '06:03:45 PM', 'error'),
  createData('MD6 press', 'ABCD1126', 'Missing', '12/07/21', '08:32:15 PM', 'warning'),
  createData('Lunch Box Press B1500A', 'ABCD1127', 'Tag Damaged', '04/15/22', '01:13:05 PM', 'success'),
];

export default function BasicTable(props) {
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [activities, setActivities] = React.useState([]);
  const [requestedResponse, setRequestedResponse] = React.useState({})
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [requestReason, setRequestReason] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  // console.log(initialState)
  // const [state, dispatch] = useReducer(reducer, {});

  // const toolContext = useContext(ToolContext)


  useEffect(() => {

    const headers = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

    fetch(configData.SERVER_URL + 'tool-missing-activities?skip=' + page + '&limit=' + rowsPerPage, { headers })
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setActivities(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {

          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }


  const missingRequest = (event, row,index) => {
    console.log(event.target.value, row.id)
    setRequestReason(event.target.value)
    const customHeaders = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

    const requestOptions = {
      method: 'PATCH',
      headers: customHeaders,
      body: JSON.stringify({
        "request_status": "Requested",
        "request_type": event.target.value

      })
    };
    fetch(configData.SERVER_URL + 'tool-missing-activities/' + row.id, requestOptions)
      .then(res => {
        setOpen(true);

        if (res.status != 200) {
          setMsg('Tool Missing activity is already captured.')
          setSeverity('warning')
          throw new Error(res.message)

        } else {
          setMsg('You have Requested successfully.')
          setSeverity('success')
        }
        return res.json()
      })
      .then(
        (result) => {
          // setRequestedResponse(result);
          console.log(result)
          let newArr = [...activities]; // copying the old datas array

            newArr[index] = {...newArr[index],
                          
                            request_type:result.request_type,
                            request_status:result.request_status,
                            requested_on:result.requested_on,
                            approved_on:result.approved_on,
                            replaced_tool:result.replaced_tool

            
            
                      }; // replace e.target.value with whatever you want to change it to
          
             setActivities(newArr)


        },

        (error) => {
          setOpen(true);
          setMsg('Something went wrong! Please try again.')
          setSeverity('error')
          console.log(error)
        }
      )

  }
  const convertDateFormat = (nextSd) => {
    const d = new Date(nextSd)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

    // console.log(`${da}-${mo}-${ye}`)
    return `${mo}-${da}-${ye}`;
  }

  // const dateFormat = (dateObject) => {
  //   let dateObj = new Date(dateObject);
  //   let month = dateObj.getUTCMonth() + 1; //months from 1-12
  //   let day = dateObj.getUTCDate();
  //   let year = dateObj.getUTCFullYear();

  //   return year + "/" + month + "/" + day;
  // }

  const getReplaceToolName = (id) => {

    let toolName = props.tools.filter((t) => t.id == id)

    console.log(toolName)
    return toolName.length > 0 ? toolName[0].name : 'Not Assigned'
  }

  const requestStatus = (request_status) => {

    if (request_status && request_status == 'Approved') {

      return <Chip label={request_status} color="success" />


    } else if (request_status == 'Requested') {
      return <Chip label={request_status} color="warning" />
    }
    else {
      return <Chip label='Action required' color="error" />
    }

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (


    <Paper
      sx={{
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 240,
      }}
    >
      {/* <S/tack spacing={2} sx={{ width: '100%' }}> */}

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>

      {/* <Typography sx={{ mb: 2, fontWeight: '700' }} variant="h5">Activity</Typography> */}
      <TableContainer className="table-container" component={Paper} variant="outlined">

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tool Name</TableCell>
              <TableCell align="center">Request Type</TableCell>
              <TableCell align="center">Request Status</TableCell>
              <TableCell align="center">Reported On</TableCell>
              <TableCell align="center">Approved On</TableCell>
              <TableCell align="center">Replaced Tool</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
            activities.length > 0 ?
            activities.map((row,index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <List sx={{ p: 0, maxWidth: '100%', bgcolor: 'background.paper' }}>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemAvatar>
                        <label position="relative" htmlFor="icon-button-file">
                          <Avatar sx={{ width: 60, height: 60, bgcolor: 'rgb(25, 118, 210, .075)', mr: 2 }} alt="" src="">
                            <ToolIcon color="primary" size="small" />
                          </Avatar>
                        </label>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ mb: 1, fontWeight: '600' }}
                              color="primary"
                            >
                              {row['tool'].name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ fontSize: '.75rem' }}
                            >
                              {row['tool'].model}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </TableCell>
                <TableCell align="center">{row.request_type ? (row.request_type == 'Faulty' ? 'Defective Tag' : row.request_type)  : 'New'}</TableCell>
                <TableCell align="center">
                  {

                    requestStatus(row.request_status)
                  }
                </TableCell>

                <TableCell align="center">{row.missed_on ? convertDateFormat(row.missed_on) : '---'}</TableCell>
                <TableCell align="center">{row.approved_on ? convertDateFormat(row.approved_on) : '---'}</TableCell>

                <TableCell align="center">{row.replaced_tool ? getReplaceToolName(row.replaced_tool) : '---'}</TableCell>
                <TableCell style={{ width: '14%' }} align="center">

                  <FormControl fullWidth disabled={row.request_status == null ? false : true} >
                    <InputLabel id="demo-simple-select-label">{row.request_status == null ? 'Request' : 'Requested'}</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      autoWidth
                      value={row.request_type}
                      label="Request"
                      size="normal"
                      onChange={(ev) => missingRequest(ev, row,index)}
                    >
                      <MenuItem value='Missing'>Missing</MenuItem>
                      <MenuItem value='Faulty'>Defective Tag</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))
            : 
           <TableRow>
                  <TableCell colSpan={6} align="center"> No Records </TableCell>
                </TableRow>
                }
          </TableBody>
          
        </Table>
      </TableContainer>
    </Paper>
  );
}
