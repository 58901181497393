import React, { useEffect } from 'react';
// import CircleNotificationsSharpIcon from '@mui/icons-material/CircleNotificationsSharp';
import NotificationsActiveSharpIcon from '@mui/icons-material/NotificationsActiveSharp';
import Badge from '@mui/material/Badge';
import AlertDialog from '../Tables/alert';
import {
    Box,
    Avatar,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    ListItemAvatar,
    Modal,
    Button,
    AppBar,
    Toolbar,
} from '@mui/material';
import {
    Logout,
    // MenuIcon,
    Notifications,
    LocalShippingOutlined,
    LocationOnOutlined,
    PhoneOutlined,
    AccountBoxOutlined,
    PhotoCamera,
    Build,
    DriveFileRenameOutline,
    DocumentScanner,
    
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import configData from "../../config.json";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
} from "react-router-dom";
// import { white } from 'chalk';
import BuildOutlined from '@mui/icons-material/BuildOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Logo from './bge-white-logo.png';
import Auth from '../../auth'
import Battery from './battery-level'
const Input = styled('input')({
    display: 'none',
});

const style = {
    width: '100%',
    maxWidth: 700,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    display: 'flex',
    p: 7,
    position: 'relative',
};

const popupTypo = {
    color: '#333',
    fontSize: '.9rem',
    fontWeight: 500,
    letterSpacing: '.5px',
    padding: '10px 0'
    // marginBottom: 1.5,
    // display: 'inline-block',
}

const middle = {
    color: '#555',
    display: 'inline-block',
    verticalAlign: 'middle',
}

const uploadButton = {
    position: 'absolute',
    background: 'white!important',
    top: 70,
    left: 70,
    // borderRadius: 3,
    border: '1px solid'
}

export default function AccountMenu(props) {
    // Profile Event
    const childRef = React.useRef();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const [driverInfo, setDriverInfo] = React.useState({});
    const [vehicleInfo, setVehicleInfo] = React.useState({});
    const [notifyEl, setNotifyAnchorEl] = React.useState(null);
    const notifyOpen = Boolean(notifyEl);
    const open = Boolean(anchorEl);
    const [snoozeEl, setSnoozeAnchorEl] = React.useState(null);
    const snoozeOpen = Boolean(snoozeEl);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [updatedImg, setUpdatedImg] = React.useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
  

    console.log(props)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Notification Event




    const closeNotify = () => {
        setNotifyAnchorEl(null);
    }

    //Snooze



    const openSnooze = (event) => {
        setSnoozeAnchorEl(event.currentTarget);
    }

    const closeSnooze = () => {
        setSnoozeAnchorEl(null);
        closeNotify();
    }


    const handleOpen = () => {
        setModalOpen(true);
        handleClose();
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const calibrationStatus = (service_status,newStatus) => {

        let status = ''
        if(newStatus){

            if (service_status == 3) {
                status = 'primary'

            } else if (service_status == 4) {
                status = 'warning'
            }
        }else {

            if (service_status == 1) {
                status = 'success'
            } else if (service_status == 2) {
                status = 'primary'
            } else if (service_status == 3) {
                status = 'warning'

            } else if (service_status == 4) {
                status = 'error'
            }
        }
        return status;

    }


    const logout = () => {
        const headers = { 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

        fetch(configData.SERVER_URL + 'auth/logout', { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);

                    Auth.logout(() => {

                        localStorage.clear()

                        history.push('/login')
                    })

                },

                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )


        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        }

    }

    const handleInputChange = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            const customHeaders = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

            const requestOptions = {
                method: 'POST',
                headers: customHeaders,
                body: JSON.stringify({ image: e.target.result })
            };

            fetch(configData.SERVER_URL + 'app/drivers/update-user-avatar/', requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);

                        setUpdatedImg(e.target.result)
                    },

                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }

    }


    const getDriverAndVehicleInfo= ()=>{
        const headers = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }
        fetch(configData.SERVER_URL + 'app/drivers/read-vehicle-info', { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result){
                    setIsLoaded(true);


                    setDriverInfo(result.driver);
                    setVehicleInfo(result.vehicle)
                    
               

                    
                    }else{
                        alert('There is no vehicle info')
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    
    

    useEffect(() => {

        getDriverAndVehicleInfo()
        // const interval = setInterval(getDriverAndVehicleInfo, 5 * 60 * 1000)
        // return () => clearInterval(interval);

    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    }


    const convertDateFormat=(nextSd)=>{
        const d = new Date(nextSd)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    
    // console.log(`${da}-${mo}-${ye}`)
    return `${mo}-${da}-${ye}`;
    }

    return (
        <React.Fragment>
            <AlertDialog ref={childRef} items={props.tools} calibrationStatus={calibrationStatus}></AlertDialog>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: '24px' }}>


                <Box sx={{ my: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => props.openSidebar('left', true)()}

                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/dashboard/tool-list" style={{display: 'inline-block', marginLeft: '10px',lineHeight: '1'}}>
                        <img src={Logo} width="90px" />
                    </Link>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right',  }}>

            </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                <Typography sx={{mr: .5}}>{props.batteryStatus.level?props.batteryStatus.level:64}%</Typography>
                <Battery level={props.batteryStatus.level?props.batteryStatus.level:64} charging={true}></Battery>
                <IconButton onClick={() => childRef.current.handleClickOpen()} sx={{ ml: 3 }}>
                <Badge badgeContent={props.notificationCount} color="secondary">
                <NotificationsActiveSharpIcon style={{ color: "white" }}  fontSize='large' />
                    </Badge>
                    </IconButton>
                    <IconButton onClick={handleClick} size="normal" sx={{ ml: 3 }}>
                        <Avatar sx={{ fontSize: '1rem' }} alt={driverInfo && driverInfo.firstName} src={updatedImg || driverInfo && driverInfo.image} />
                    </IconButton>

                </Box>
               
            </Box>
          
           
            <Menu
                anchorEl={notifyEl}
                open={notifyOpen}
                onClose={closeNotify}
            >
                <List sx={{ width: '320px', maxWidth: '100%', bgcolor: 'background.paper' }}>
                    <ListItem secondaryAction={
                        <Box>
                            <Button sx={{ mr: .5, textTransform: 'unset' }} color="inherit" size="small">
                                View
                            </Button>
                            <Button onClick={openSnooze} sx={{ textTransform: 'unset' }} color="inherit" size="small">
                                Snooze
                            </Button>
                        </Box>
                    } sx={{ pr: 2 }}>
                        <ListItemAvatar>
                            <Avatar>
                                <Build />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Test_tool_1" secondary="July 21, 2021" />
                    </ListItem>
                    <ListItem secondaryAction={
                        <Box>
                            <Button sx={{ mr: .5, textTransform: 'unset' }} color="inherit" size="small">
                                View
                            </Button>
                            <Button onClick={openSnooze} sx={{ textTransform: 'unset' }} color="inherit" size="small">
                                Snooze
                            </Button>
                        </Box>
                    } sx={{ pr: 2 }}>
                        <ListItemAvatar>
                            <Avatar>
                                <Build />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="bot_2" secondary="Aug 2, 2021" />
                    </ListItem>
                    <ListItem secondaryAction={
                        <Box>
                            <Button sx={{ mr: .5, textTransform: 'unset' }} color="inherit" size="small">
                                View
                            </Button>
                            <Button onClick={openSnooze} sx={{ textTransform: 'unset' }} color="inherit" size="small">
                                Snooze
                            </Button>
                        </Box>
                    } sx={{ pr: 2 }}>
                        <ListItemAvatar>
                            <Avatar>
                                <Build />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Test_tool_5" secondary="July 20, 2014" />
                    </ListItem>
                </List>
            </Menu>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={snoozeEl}
                open={snoozeOpen}
                onClose={closeSnooze}
            // TransitionComponent={Fade}
            >
                <MenuItem onClick={closeSnooze} sx={{ fontSize: '12px' }}>15 Minutes</MenuItem>
                <MenuItem onClick={closeSnooze} sx={{ fontSize: '12px' }}>30 Minutes</MenuItem>
                <MenuItem onClick={closeSnooze} sx={{ fontSize: '12px' }}>45 Minutes</MenuItem>
            </Menu>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box sx={{ px: 3, py: 2, width: '320px', maxWidth: '100%' }}>
                    {/* <Typography variant="h6">User Profile</Typography> */}
                    <List sx={{ pt: 0, width: '350px', maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <ListItem sx={{ borderBottom: '1px solid #ddd', pt: 0, pb: 3, px: 0 }}>
                            <ListItemAvatar>
                                <label position="relative" htmlFor="icon-button-file">
                                    <Avatar sx={{ width: 100, height: 100, mr: 4 }} alt={driverInfo && driverInfo.firstName} src={updatedImg || driverInfo && driverInfo.image} />
                                    <Input accept="image/*" id="icon-button-file" onChange={handleInputChange} type="file" />
                                    <IconButton size="small" color="primary" sx={uploadButton} aria-label="upload picture" component="span">
                                        <PhotoCamera style={{color:'#4ab057'}} sx={{  width: '20px', height: '20px' }} />
                                    </IconButton>
                                </label>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: '600', fontSize: '1.5rem' }}
                                        >
                                            {driverInfo && driverInfo.firstName + ' ' + driverInfo.lastName}
                                        </Typography>
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontSize: '.85rem' }}
                                        >
                                            {driverInfo && driverInfo.email}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem onClick={handleOpen} sx={{ px: 0, py: 2, borderBottom: '1px solid #ddd', mb: 2, cursor: 'pointer' }}>
                            <ListItemIcon sx={{ px: 2, py: 2, mr: 4, justifyContent: 'center', borderRadius: 2, background: 'rgba(0,0,0,.05)' }}>
                                <LocalShippingOutlined style={{color:'#4ab057'}} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', color: '#333', fontWeight: '600', fontSize: '1rem' }}
                                        >
                                          {vehicleInfo && vehicleInfo.name}
                                        </Typography>
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontSize: '.75rem' }}
                                        >
                                            {vehicleInfo && vehicleInfo.model}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    <Button onClick={logout} style={{background:'#4ab057'}} fullWidth variant="contained" sx={{ textTransform: 'revert' }} width="100%" startIcon={<Logout sx={{ width: '15px', height: '15px' }} />}>Log Out</Button>
                </Box>
            </Menu>

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Box sx={style}>
                    <IconButton onClick={handleModalClose} sx={{ right: 20, top: 20, position: 'absolute', opacity: .8 }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid  position="relative">

                            <Avatar sx={{ borderRadius: 2, width: 200, height: 200, mr: 5 }} alt={vehicleInfo && vehicleInfo.model} src={updatedImg || vehicleInfo && vehicleInfo.image} />
                        </Grid>
                        <Grid  sx={{ flex: '1 auto' }}>
                            <Typography id="modal-modal-title" variant="h5" component="h5" sx={{ borderBottom: '1px solid #ddd', fontWeight: 700, pb: 1, mb: 2 }}>{vehicleInfo && vehicleInfo.model}</Typography>
                            <Typography id="modal-modal-name" sx={popupTypo}>
                                <DriveFileRenameOutline style={{color:"#4ab057"}} sx={{ verticalAlign: 'middle', mr: 1.5 }} />
                                <Typography variant="span" sx={middle}><Typography variant="span" style={{color:"#4ab057"}}>Truck Name :</Typography> {vehicleInfo && vehicleInfo.name}</Typography>
                            </Typography>
                            {/* <Typography id="modal-modal-vin" sx={popupTypo}>
                                <AccountBoxOutlined style={{color:"#4ab057"}} sx={{ verticalAlign: 'middle', mr: 1.5 }} />
                                <Typography variant="span" sx={middle}><Typography variant="span" style={{color:"#4ab057"}}>VIN :</Typography> {vehicleInfo && vehicleInfo.vin}</Typography>
                            </Typography> */}
                            <Typography id="modal-modal-tools" sx={popupTypo}>
                                <BuildOutlined style={{color:"#4ab057"}} sx={{ verticalAlign: 'middle', mr: 1.5 }} />
                                <Typography variant="span" sx={middle}><Typography variant="span" style={{color:"#4ab057"}}>No. of Tools :</Typography> {vehicleInfo && vehicleInfo.tools?.length}</Typography>
                            </Typography>
                            <Typography id="modal-modal-date" sx={popupTypo}>
                                <DocumentScanner style={{color:"#4ab057"}} sx={{ verticalAlign: 'middle', mr: 1.5 }} />
                                <Typography variant="span" sx={middle}><Typography variant="span" style={{color:"#4ab057"}}>Last Scanned Date :</Typography> {vehicleInfo  && vehicleInfo.lastScanDateTime ? convertDateFormat(vehicleInfo.lastScanDateTime):'---'}</Typography>
                            </Typography>
                            {/* <Typography id="modal-modal-date" sx={popupTypo}>
                                <LocationOnOutlined color="primary" sx={{ verticalAlign: 'middle', mr: 1.5 }} />
                                <Typography variant="span" sx={middle}><Typography variant="span" color="primary">Last Scanned Location :</Typography> Farmville, VA</Typography>
                            </Typography> */}



                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </React.Fragment>
    );
}