
export function reducer(state={}, action) {
  switch (action.type) {
    case 'setToolData':
       state= action.payload;
       return state
       case 'getToolData':
        state= action.payload;
        return state
     
    default:
      return state;
  }
}