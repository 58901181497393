import React, { useReducer, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Paper,
    Button,
    IconButton,
    Badge,
    Box,
    Typography,
    Modal,
    Tooltip,
    Chip,
} from '@mui/material';
import Info from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import configData from "../../config.json";
import { useHistory } from "react-router-dom";
import Refresh from '@mui/icons-material/Refresh';
import auth from '../../auth'
import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import { reducer } from './reducer';
import AlertDialog from './alert';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#4ab057',
    border: '1px solid #4ab057',
    boxShadow: 'none',
    lineHeight: '1',
    paddingBottom: '6px!important',
}));

function createData(toolName, toolId, availability, calibration, calibrationStatus) {
    return { toolName, toolId, availability, calibration, calibrationStatus };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    outline: 'none',
    textAlign: 'center',
    p: 5,
};

export const ToolContext  = React.createContext()


export default function BasicTable({getToolListVAlue,passBatteryStatus}) {
    const [open, setOpen] = React.useState(false);
    const [openRowData, setOpenRowData] = React.useState({})
    const [requested, setRequested] = React.useState(false)
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [lastFetchTime, setLastFetchTime] = React.useState('')
    const history = useHistory()
    const [noTruck, setNoTruck] = React.useState(false)
    const childRef = React.useRef();

    const [backDropOpen, setBackDropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackDropOpen(false);
    };

    const [state, dispatch] = useReducer(reducer, {});
    const [lastScannedTime,setLastScannedTime] = React.useState('')


    const handleOpen = (cRow) => {
        setOpen(true)
        setOpenRowData(cRow)


    }

   
    const handleClose = () => setOpen(false);

    const getDriverAndVehicleInfo= ()=>{
        const headers = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }
        fetch(configData.SERVER_URL + 'app/drivers/read-vehicle-info', { headers })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result){
                    // setIsLoaded(true);


                    // setDriverInfo(result.driver);
                    // setVehicleInfo(result.vehicle)
                    if(result && result.vehicle){
                        if(result.vehicle.hasOwnProperty('hubBattLevel')){
                    let val = { 'level':result.vehicle.hubBattLevel,'charging':false}
                     passBatteryStatus(val)
                        }
                    
                    }
                   setLastScannedTime(result.vehicle.lastScanDateTime)

                    
                    }else{
                        alert('There is no vehicle info')
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

    const getToolList = () => {
        setBackDropOpen(true)
        const headers = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

        fetch(configData.SERVER_URL + 'app/drivers/read-tools-info', { headers })
            .then(res =>res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                    getToolListVAlue(result)
                    getDriverAndVehicleInfo()
                    let soonForCalibration = 0;
                    let dueForCalibration = 0;
                    let missing = 0
                    if(result.length>0) {
                        result.filter(function (d) {
                            if (d.availability) {
                                if (d.service_status === 4) {
                                    dueForCalibration++;
                                } else if (d.service_status == 3) {
                                    soonForCalibration++
                                }
                            }else{
                                missing++
                            }
                        });
                    }
                    let t = soonForCalibration+dueForCalibration+missing;
                    if(t>0){
                        childRef.current.handleClickOpen()
                    }
                    setBackDropOpen(false)

                    // setLastFetchTime(new Date().toLocaleString('en-US'))

                },
               
                (error) => {

                    setIsLoaded(true);
                }
            )
    }
    
    useEffect(() => {
        getToolList()
        const interval = setInterval(getToolList, 5 * 60 * 1000)

        return () => clearInterval(interval);
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const calibrationStatus = (service_status,newStatus) => {

        let status = ''
       if(newStatus){

           if (service_status == 3) {
               status = 'primary'

           } else if (service_status == 4) {
               status = 'warning'
           }
       }else {

           if (service_status == 1) {
               status = 'success'
           } else if (service_status == 2) {
               status = 'primary'
           } else if (service_status == 3) {
               status = 'warning'

           } else if (service_status == 4) {
               status = 'error'
           }
       }
        return status;

    }

    const getDateTime=(val)=>{


        if(val) {
            return new Date(
                `${val.split(' ')[0]}T${
                    val.split(' ')[1]
                }Z`
            ).toLocaleString(
                'en-US',
                Intl.DateTimeFormat().resolvedOptions().timeZone
            )
        }else{
            return new Date().toLocaleString(
                'en-US',
                Intl.DateTimeFormat().resolvedOptions().timeZone
            )
        }

    }

    const convertDateFormat = (nextSd) => {
        const d = new Date(nextSd)
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

        console.log(`${da}-${mo}-${ye}`)
        return `${mo}-${da}-${ye}`;
    }

    return (
    
        <ToolContext.Provider value={{toolState:state,toolDispatch: dispatch}}>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropOpen}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <AlertDialog ref={childRef} items={items} calibrationStatus={calibrationStatus}></AlertDialog>
        <Paper
            sx={{
                p: 5,  
                display: 'flex',
                flexDirection: 'column',
                minHeight: 240,
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container wrap="wrap" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Item style={{color:'black'}} sx={{ px: 3 }}>Last scanned : {getDateTime(lastScannedTime)}</Item>
                    </Grid>
                    <Grid item sx={{}}>
                        <Badge className="badge" badgeContent={''} color='success'></Badge>
                        <Typography sx={{fontSize: '.9rem', display: 'inline-block', verticalAlign: 'middle',ml: 2, mr: 4 }}> {'>3M'}  </Typography>
                        <Badge badgeContent={''} color='primary'></Badge>
                        <Typography sx={{fontSize: '.9rem', display: 'inline-block', verticalAlign: 'middle',ml: 2, mr: 4 }}> 1M - 3M</Typography>
                        <Badge badgeContent={''} color='warning'></Badge>
                        <Typography sx={{fontSize: '.9rem', display: 'inline-block', verticalAlign: 'middle',ml: 2, mr: 4 }}> {'<1M'}  </Typography>
                        <Badge badgeContent={''} color='error'></Badge>
                        <Typography sx={{fontSize: '.9rem', display: 'inline-block', verticalAlign: 'middle',ml: 2, mr: 4 }}>Due for Calibrate</Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right' }}>
                        <Button  onClick={() => getToolList()} style={{ backgroundColor: '#4ab057', color: '#FFFFFF' }} variant="contained" sx={{ textTransform: 'unset' }}>
                            <Refresh sx={{ fontSize: '1rem', mr: 1 }} /> Refresh
                        </Button>
                    </Grid>

                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>


            </Box>
            <TableContainer component={Paper} variant="outlined">
                <Table sx={{ minWidth: 650, verticalAlign: 'middle' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#efefef" }}>
                            <TableCell>Tool Name</TableCell>
                            <TableCell align="center">Availability</TableCell>

                            <TableCell align="center">Tag ID</TableCell>
                            <TableCell align="center">Manufacturer</TableCell>

                            <TableCell align="center">Calibration Status</TableCell>
                            {/* <TableCell></TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                {items&& items.length >0 ?
                
                     items.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" sx={{ whiteSpace: 'nowrap' }} scope="row">{row.name} <Info onClick={() => handleOpen(row)} sx={{ verticalAlign: 'middle' }} /></TableCell>
                                <TableCell align="center">{row.availability ? <Chip color="success" label="Available" /> : <Chip color="error" label="Not Available" />}</TableCell>

                                <TableCell align="center">{row.tagID}</TableCell>
                                <TableCell align="center"> {row.manufacturer}</TableCell>

                                {/* <TableCell align="center">{row.lastService}</TableCell> */}
                                <TableCell align="center"> <Tooltip title={convertDateFormat(row.nextService)}><Badge badgeContent={''} color={calibrationStatus(row.service_status)}></Badge></Tooltip></TableCell>
                            </TableRow>
                        ))
                        :
                        <TableRow>
                        <TableCell colSpan={6} align="center"> No Tool assigned for your vehicle </TableCell>
                      </TableRow>
                        }
                    </TableBody>
                </Table>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <IconButton onClick={handleClose} sx={{ right: 20, top: 20, position: 'absolute', opacity: .8 }}>
                            <CloseIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* <img width="100px" height="100px" alt="" src="/public/user1.png" /> */}
                            <img width="100px" height="100px" alt={openRowData.name} src={openRowData.image} />
                            <Box sx={{ textAlign: 'left', ml: 3 }}>
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '.9rem' }}>
                                    {console.log(openRowData)}
                                    <p><strong>Name:</strong> {openRowData.name}</p>
                                    <p><strong>Manufacturer:</strong> {openRowData.manufacturer}</p>
                                    <p><strong>Model:</strong> {openRowData.model}</p>
                                    <p><strong>Cycle:</strong>{openRowData.cycle}</p>
                                    <p><strong>Last service date:</strong> {openRowData.lastService}</p>
                                    <p><strong>Next service date:</strong> {openRowData.nextService}</p>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </TableContainer>
        </Paper>
        </ToolContext.Provider>
    );
}
