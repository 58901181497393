import React from "react";

export default function Battery({ level, charging }) {

  return (
    
    <svg width="50px" viewBox="0 0 257.13 142.85">
      <title>Battery</title>
      <path fill="#ffffff" d="M257.13,92.85a14.24,14.24,0,0,1-14.29,14.29V125A17.91,17.91,0,0,1,225,142.85H17.86A17.91,17.91,0,0,1,0,125V17.86A17.91,17.91,0,0,1,17.86,0H225a17.91,17.91,0,0,1,17.86,17.86V35.71A14.24,14.24,0,0,1,257.13,50Zm-14.29,0V50H228.56V17.86A3.53,3.53,0,0,0,225,14.29H17.86a3.52,3.52,0,0,0-3.57,3.57V125a3.52,3.52,0,0,0,3.57,3.57H225a3.53,3.53,0,0,0,3.57-3.57V92.85Z" />
      <rect x="26" y="26" className={charging ? "charging": ""} width={(level / 100) * 190} height="90" fill={(level >=  60) ? "#37F53B" : (level <= 10) ? "red": "orange" }></rect>
      {/* <text x="80" y="98" className="battery-text" font-weight="500" font-size="80px" fill="#ffffff">{charging ? "ϟ": ''}</text> */}
    </svg>
  );
}
