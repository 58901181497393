import React from 'react';
import { Route, Redirect } from "react-router-dom";
import Auth from './auth'

const AuthGuard = ({ component: Component,  ...rest }) => (
    
    
    <Route {...rest} render={props => {
        
     
        if(Auth.isAuthenticated() === true)
        {
            return <Component {...props} />

        }else{
return <Redirect to='/login' />
        }
           
    

    } }
    />
    
)

export default AuthGuard;