import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pic from './assets/imgs/bge.svg'
import { makeStyles } from "@mui/styles";
import configData from "../../config.json";
import { useHistory } from "react-router-dom";
import Auth from '../../auth'
import auth from '../../auth';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {' © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" target="_blank" href="https://nividit.com/">
                Nivid Technologies
            </Link>
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const useStyles = makeStyles({

sx_bg:{ backgroundImage: 'url(https://bge.nsmart.io/_nuxt/img/Image.9ccf095.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

},
box:{

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '400px',
    margin: 'auto 0',
}

});

export default function SignInSide(props) {
    const classes = useStyles();
    const history = useHistory();

    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tokens, setTokens] = React.useState('');
    const [incorrect,setIncorrect] = React.useState(false)


    const autoRefreshToken=()=>{

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({token: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refreshToken')})
        };
        fetch(configData.SERVER_URL+'auth/refresh-token',requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            
            setIsLoaded(true);
            setTokens(result);
            localStorage.setItem('token',result.token)
            localStorage.setItem('refreshToken',result.refreshToken)

            
          },
         
          (error) => {
            setIsLoaded(true);
            auth.logout(()=>{
                history.push('/login')

            })
            setError(error);
          }
        )
        
    }
    
      

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({username: data.get('email'),
            password: data.get('password')})
        };
        fetch(configData.SERVER_URL+'auth/login',requestOptions)
        .then(res => {

           if(res.status !=200){
            setIncorrect(true)
            throw new Error(res.message)
           }     
            
            return res.json()
        
        })
        .then(
          (result) => {
            

            setIsLoaded(true);
            
            Auth.login(()=>{
                setTokens(result);
                localStorage.setItem('token',result.token)
                localStorage.setItem('refreshToken',result.refreshToken)
                console.log('authendicated successfully')
                history.push('/dashboard/tool-list')
                setInterval(autoRefreshToken, 1000 * 60 * 60 * 24);


            })

          },
         
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } 
      
    
    };

    const resetForm=()=>{

        if(incorrect)
        setIncorrect(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item   md={5} component={Paper} elevation={6} square sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 4,
                    background: 'rgb(245, 250, 250)',
                }}>
                    <Box
                        className={classes.box}
                    >
                        <img width="100" src="https://bge.nsmart.io/_nuxt/img/Logo.652df8e.svg" />
                        <Typography sx={{ color: 'green', mt: 2 }} variant="p">Please sign-in to continue</Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                error= { incorrect}

                                fullWidth
                                size="large"
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={()=>resetForm()}
                                
                            />
                            <TextField
                                margin="normal"
                                error= { incorrect}
                                required
                                fullWidth
                                size="large"
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={()=>resetForm()}
                                autoComplete="current-password"
                                helperText={ incorrect ? "Incorrect Username or Password! ":''}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            <Button
                                type="submit"
                                size="large"
                                style={{background:'#4ab057'}}
                                fullWidth
                                variant="contained"
                                sx={{ p: 1.5, mt: 3, mb: 2, textTransform: 'revert' }}
                            >
                                Sign In
                            </Button>
                            {/* <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Link href="/forgot-pwd" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                               
                            </Grid> */}
                        </Box>
                    </Box>
                    <Copyright />
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{ backgroundImage: 'url(https://bge.nsmart.io/_nuxt/img/Image.9ccf095.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}

                />
            </Grid>
        </ThemeProvider>
    );
}