import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Header from './Header';
import TruckTools from '../Tables/tool-list';
import ActivityTabs from '../activity-tabs';
import Drawer from '@mui/material/Drawer';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    Link
} from 'react-router-dom';

import Sidebar from './Sidebar';

function Copyright(props) {
    return (
        <Typography align="center" {...props}>
            {' © '}
            {new Date().getFullYear()}
            {' '}
            <Link className="copyright" target="_blank" to="https://nividit.com/">Nivid Technologies</Link>
            {'. All rights reserved.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const mdTheme = createTheme();

function DashboardContent(props) {
    let { id } = useParams()
   
    const [tools, setTools] = React.useState({});
    const [notificationCount , setNotificationCount] = React.useState(0);

    const [state, setState] = React.useState({

        left: false

    });
    const [battery, setBattery] = React.useState({ level: 40, charging: false });


    const toggleDrawer = (anchor, open) => (event) => {
        //   debugger
        console.log(anchor, open)

        setState({ ...state, [anchor]: open });
    };

    const getToolListVAlue = (data)=>{
       
        let soonForCalibration = 0;
        let dueForCalibration = 0
        let missing=0;
        if(data.length>0){
            data.filter(function (d) {
                if (d.availability) {
                    if (d.service_status === 4) {
                        dueForCalibration++;
                    } else if (d.service_status == 3) {
                        soonForCalibration++
                    }
                }else{
                    missing++
                }
            });
        }
        // else{
        //     dueForCalibration = 2;
        //     soonForCalibration = 1;
        // }
        let t = soonForCalibration+dueForCalibration+missing;
        setNotificationCount(t)
        setTools(data)
    }


    const passBatteryStatus = (val)=>{

        setBattery(!val ? 64:val)
    }



    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ background: "#4ab057", zIndex: "999" }} position="absolute" >
                    <Header openSidebar={toggleDrawer} batteryStatus={battery}  notificationCount={notificationCount} tools={tools}/>
                </AppBar>
                <Drawer
                    anchor='left'
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                >
                    <Sidebar openSidebar={toggleDrawer} />
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Container maxWidth="xl" sx={{ pt: '100px', flex: '1 auto' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} xl={12}>
                                {id == 'tool-list' ? <TruckTools getToolListVAlue={getToolListVAlue} passBatteryStatus={passBatteryStatus}  /> : <ActivityTabs  tools={tools}/>}
                            </Grid>
                        </Grid>
                    </Container>
                    <Copyright sx={{ background: '#4ab057', color: '#fff', fontSize: '.8rem', fontWeight: '300', mt: 3, p: 1 }} />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default DashboardContent