import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Chip,
} from '@mui/material';
import ToolIcon from '@mui/icons-material/BuildOutlined';
import MuiAlert from '@mui/material/Alert';
import configData from "../config.json";
import './Tables/activity-list.css';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import Snackbar from '@mui/material/Snackbar';
// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function BasicTable() {

  const [cal, setCal] = React.useState({})
  const [calibrationReqStatus, setCalibrationReqStatus] = React.useState('')
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const getToolList = () => {
    const headers = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

    fetch(configData.SERVER_URL + 'app/drivers/read-tools-info', { headers })
      .then(res => {

        return res.json()
      }
      )
      .then(
        (result) => {
          // setIsLoaded(true);
          // setItems(result);
          // getToolListVAlue(result)
          // setLastFetchTime(new Date().toLocaleString('en-US'))
          if(!result.hasOwnProperty('error')){
          let calb = result.filter(function (e) {
            return e.service_status === 4 && e.availability;
          });
          setCal(calb)
        }

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {

          setOpen(true);
          setMsg('Something went wrong! Please try again.')
          setSeverity('error')
        }
      )
  }
  React.useEffect(() => {


    getToolList()


  }, [])

  const calibrationRequest = (event, row,index) => {
    setCalibrationReqStatus(event.target.value)
    const customHeaders = { 'Content-Type': 'application/json', 'X-Authorization': 'Bearer ' + localStorage.getItem('token') }

    const requestOptions = {
      method: 'PATCH',
      headers: customHeaders,
      body: JSON.stringify({
        "clb_status": event.target.value === 'Requested' ? "Approval-Pending" : 'Done',
        "clb_req_type": event.target.value,
        "clb_requested_on": new Date().toISOString()

      })
    };
    fetch(configData.SERVER_URL + 'app/drivers/update-tool-clb-status/' + row.id, requestOptions)
      .then(res => {
        if (event.target.value === 'Requested') {
          setMsg('Calibration request has been made successfully.')
          setSeverity('success')
        } else {
          setMsg('Tool has been calibrated successfully.')
          setSeverity('success')
        }

        return res.json()
      }).then(
        (result) => {

          setOpen(true);

          if (event.target.value == 'Self') {
            let fValues = cal.filter((e) => {


              return e.id != result.id

            })
            setCal(fValues)
          }else{
            console.log(cal)
            // console.log('index: ' + index);
            // console.log('property name: '+ e.target.name);
            let newArr = [...cal]; // copying the old datas array
            
            newArr[index] = result; // replace e.target.value with whatever you want to change it to
          
             setCal(newArr)
          }
        },

        (error) => {
          setOpen(true);
          setMsg('Something went wrong! Please try again.')
          setSeverity('error')

        }
      )
  }
  const convertDateFormat = (nextSd) => {
    const d = new Date(nextSd)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

    // console.log(`${da}-${mo}-${ye}`)
    return `${mo}-${da}-${ye}`;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 240,
        }}
      >
        <TableContainer className="table-container" component={Paper} variant="outlined">
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {msg}
            </Alert>
          </Snackbar>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tool Name</TableCell>
                <TableCell align="center">Request Type</TableCell>
                <TableCell align="center">Request Status</TableCell>
                <TableCell align="center">Cycle</TableCell>
                <TableCell align="center">Requested On    </TableCell>

                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {cal.length > 0 ?
                cal.length > 0 && cal.map((row,index) => (

                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <List sx={{ p: 0, maxWidth: '100%', bgcolor: 'background.paper' }}>
                        <ListItem sx={{ p: 0 }}>
                          <ListItemAvatar>
                            <label position="relative" htmlFor="icon-button-file">
                              <Avatar sx={{ width: 60, height: 60, bgcolor: 'rgb(25, 118, 210, .075)', mr: 2 }} alt="" src="">
                                <ToolIcon color="primary" size="small" />
                              </Avatar>
                            </label>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  sx={{ mb: 1, fontWeight: '600' }}
                                  color="primary"
                                >
                                  {row.name}
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ fontSize: '.75rem' }}
                                >
                                  {row.model}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </List>                      
                    </TableCell>
                    <TableCell align="center">{row.clb_req_type ? row.clb_req_type : 'New'}</TableCell>
                    <TableCell align="center">{row.clb_status ? <Chip color="warning" label={row.clb_status} /> : <Chip color="error" label="Action Required" />}</TableCell>
                    <TableCell align="center">{row.cycle}</TableCell>

                    <TableCell align="center">{row.clb_requested_on ? convertDateFormat(row.clb_requested_on) : '---'}</TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth disabled={row.clb_requested_on ? true : false} >
                        <InputLabel id="demo-simple-select-label">{row.clb_requested_on ? row.clb_req_type : 'Request'}</InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          autoWidth
                          value={calibrationReqStatus}
                          label="Request"
                          size="normal"
                          onChange={(ev) => calibrationRequest(ev, row,index)}
                        >
                          <MenuItem value='Requested'>Request</MenuItem>
                          <MenuItem value='Self'>Self</MenuItem>
                        </Select>
                      </FormControl>


                    </TableCell>
                  </TableRow>
                ))
                :

                <TableRow>
                  <TableCell colSpan={6} align="center"> No Records </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}
