import './App.css';
import React, { useContext, createContext, useState } from "react";

import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
 } from 'react-router-dom';
import AuthGuard from './producted-routes'



function App() {


  return (
    <div className="App">
          <Router>

         <Switch>
         <AuthGuard exact path="/dashboard/:id" component={Dashboard} />

          <Route exact path="/login" component={Login}/>
          <Route exact path="/" component={Login}/>


        <Route path="*" component={()=>"404 NOT FOUND"} />
          
          </Switch>
          </Router>
         
    </div>
  );
}

export default App;
