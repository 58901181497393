import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ToolListIcon from '@mui/icons-material/BuildOutlined';
import ActivityIcon from '@mui/icons-material/ListAlt';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from 'react-router-dom';
import Logo from '../../logo.svg';



export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({

    left: false

  });
  const history = useHistory()

  const redirectTo= (to)=>{
    props.openSidebar('left', false)()
    history.push(to)

  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation">
      <Box sx={{ my: 2, mx: 'auto' }}>
        <Link to="/"><img width="100" style={{ marginLeft: 55 }} src={Logo} /></Link>
      </Box>

      <List>
        <ListItem button className="active" sx={{ borderRadius: 2, my: 1 }} onClick={() => redirectTo('/dashboard/tool-list')}>
          <ListItemIcon>
            <ToolListIcon sx={{ width: '20px', height: '20px' }} />
          </ListItemIcon>
          <ListItemText primary="Tool List" />
        </ListItem>
        <Divider />

        <ListItem button sx={{ borderRadius: 2, my: 1 }} onClick={() => redirectTo('/dashboard/activity')}>
          <ListItemIcon>
            <ActivityIcon sx={{ width: '20px', height: '20px' }} />
          </ListItemIcon>
          <ListItemText primary="Activity" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

        {list('left')}

      </React.Fragment>
    </div>
  );
}
